import type { Action, Store } from "redux";
import type { Persistor } from "redux-persist";

import type { ModuleBuilderFunction, ModuleData } from "../modules/interface";

export interface ModuleFetcher {
    (id: string): Promise<{
        default: ModuleData;
        createModule?: ModuleBuilderFunction;
    } | null>;
}

export interface DynamicStoreComplement {
    modules: ModuleData[];
    persistor: Persistor;
    initPersistor: () => void;
    addModules: (mds: ModuleData[]) => void;
    removeModules: (
        mds: Partial<Pick<ModuleData, "hash" | "id" | "name">>[],
        force?: boolean,
    ) => void;
    toggleModule: (md: ModuleData, force?: boolean) => void;
    updateModule: (
        md: Partial<Pick<ModuleData, "hash" | "id" | "name">>,
        newData: Partial<Pick<ModuleData, "fixed" | "hash" | "updatedAt">>,
    ) => void;
}

export type DynamicStore<
    S = Record<string, never>,
    A extends Action<any> = Action,
    Ext extends Record<string, any> = NonNullable<unknown>,
> = Store<S, A> & DynamicStoreComplement & Ext;

export const internalId = "@@internal";

export type InternalPersistency = { name: string; updatedAt: number | null }[];
