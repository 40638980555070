import { lazy } from "react";

import { FlowSteps } from "@redux/modules/login/signup/interface";

const AsaasAccountInfoStep = lazy(() => import("./Asaas/AccountInfo"));
const AsaasPaymentInfoStep = lazy(() => import("./Asaas/PaymentInfo"));
const AsaasConfirmationStep = lazy(() => import("./Asaas/Confirmation"));
const HotmartAccountInfoStep = lazy(() => import("./Hotmart/AccountInfo"));
const HotmartDocumentValidationStep = lazy(
    () => import("./Hotmart/DocumentValitation"),
);
const HotmartPaymentStep = lazy(() => import("./Hotmart/Payment"));
const HotmartConfirmationStep = lazy(() => import("./Hotmart/Confirmation"));

export const stepsRelation = {
    [FlowSteps.ASAAS_ACCOUNT_INFO]: AsaasAccountInfoStep,
    [FlowSteps.ASAAS_PAYMENT]: AsaasPaymentInfoStep,
    [FlowSteps.ASSAS_CONFIRMATION]: AsaasConfirmationStep,
    [FlowSteps.HOTMART_ACCOUNT_INFO]: HotmartAccountInfoStep,
    [FlowSteps.HOTMART_DOCUMENT_VALIDATION]: HotmartDocumentValidationStep,
    [FlowSteps.HOTMART_PAYMENT]: HotmartPaymentStep,
    [FlowSteps.HOTMART_CONFIRMATION]: HotmartConfirmationStep,
};
