// //Skus
// import MySkus from "../../pages/myskus";
// import Skus from "../../pages/skus";
// import Sku from "../../pages/skus/sku";
// import SkuStock from "../../pages/skus/skustock";
// import NewSku from "../../pages/skus/components/NewSkus";
// import Purchases from "../../pages/skus/purchases";
// import Conference from "../../pages/skus/conference";
// import Purchase from "../../pages/skus/purchases/purchase";
// import PurchaseOrder from "../../pages/skus/purchases/components/PurchaseOrder";
// import PurchaseItems from "../../pages/skus/purchases/purchase/components/Items";
// import PurchasePayments from "../../pages/skus/purchases/purchase/components/Payments";
// import Category from "../../pages/skus/categories/category";
// // Brands
// import Brands from "../../pages/skus/brands";
// import Brand from "../../pages/skus/brands/brand";
// import NewBrand from "../../pages/skus/brands/components/NewBrand";
// import SkusSuppliers from "../../pages/skus/skussuppliers";
// import BuyOrder from "../../pages/skus/buyorder";
// import BuyOrderAnalysis from "../../pages/skus/buyorder/BuyOrderAnalysis";
// import Categories from "../../pages/skus/categories";
// import Inventory from "../../pages/skus/inventory";
// import NewInventory from "../../pages/inventory";
// import InventoryItem from "../../pages/inventory/components/inventoryItem";
// import SkuLocation from "../../pages/skus/skulocation";
// import SkuMovement from "../../pages/skus/skumovement";
// import SkuKardex from "../../pages/skus/skumovement/kardex";
// import SkusBacth from "../../pages/skus/skusbatch";
// import SkuPricing from "../../pages/skus/skupricing";
// import SkuSellingAnalysis from "../../pages/skus/skusellinganalysis";
// import SkuVariation from "../../pages/skus/skuvariation";
// import Tax from "../../pages/skus/taxes/tax";
// import Taxes from "../../pages/skus/taxes";
// import TypeSkuVariation from "../../pages/skus/typeskuvariation";
// import Warehouse from "../../pages/skus/warehouse";
// import BuyOrde from "../../pages/skus/buyorder/buyorde";
// import BuyOrdeItems from "../../pages/skus/buyorder/buyorde/components/Items";

// //Accounts
// import AccountsPayable from "../../pages/accounts/accountspayable";
// import AccountPayable from "../../pages/accounts/accountspayable/accountpayable";
// import AccountsReceivable from "../../pages/accounts/accountsreceivable";
// import AccountReceivable from "../../pages/accounts/accountsreceivable/accountreceivable";
// import Bank from "../../pages/accounts/bank";
// import SupplierCategories from "../../pages/accounts/suppliercategories";
// import Suppliers from "../../pages/accounts/suppliers";
// import SupplierView from "../../pages/accounts/suppliers/supplier";
// import TypeAccountCategory from "../../pages/accounts/typeaccountcategory";
// import TypeDocument from "../../pages/accounts/typedocument";
// import TypePayment from "../../pages/accounts/typepayment";
// import TypeOrder from "../../pages/accounts/typeorder";
// import TypeAccount from "../../pages/accounts/typeaccounts";

// //Subscriptions
// import Plans from "../../pages/subscriptions/plans";
// import Plan from "../../pages/subscriptions/plans/plan";
// import Subscriptions from "../../pages/subscriptions";
// import Subscription from "../../pages/subscriptions/subscription";
// import Promotions from "../../pages/subscriptions/promotions";
// import Bills from "../../pages/subscriptions/bills";
// import Bill from "../../pages/subscriptions/bills/bill";
// import ActionsBills from "../../pages/subscriptions/bills/components/Actions";
// import Changes from "../../pages/subscriptions/changes";

// //Orders
// import Orders from "../../pages/orders";
// import GnreRule from "../../pages/orders/gnrerule";
// import Gnre from "../../pages/orders/gnre";
// import Order from "../../pages/orders/order";
// import OrderItems from "../../pages/orders/order/components/Items";
// import OrderPayments from "../../pages/orders/order/components/Payments";
// import OrderEvents from "../../pages/orders/order/components/Events";
// import OrderFiles from "../../pages/orders/order/components/Files";
// import OrderPackages from "../../pages/orders/order/components/Packages";
// import OrderActionList from "../../pages/orders/components/Actions";
// import NewOrder from "../../pages/orders/components/NewOrder";
// import Invoice from "../../pages/orders/invoice";
// import Pricer from "../../pages/pricer";

// //Logistics
// import OpLogistics from "../../pages/logistics/oplogistics";
// import OpLogistic from "../../pages/logistics/oplogistics/oplogistic";
// import Carriers from "../../pages/logistics/carriers";
// import Outbound from "../../pages/logistics/outbound";
// import Packages from "../../pages/logistics/packages";
// import PackageView from "../../pages/logistics/packages/package";
// import Comments from "../../pages/logistics/packages/package/components/Comments";
// import TrackingEvents from "../../pages/logistics/packages/package/components/TrackingEvents";
// import CollectionList from "../../pages/logistics/collectionlist";
// import Packing from "../../pages/logistics/packing";
// import PickingListPrint from "../../pages/logistics/pickinglistprint";
// import TrackPi from "../../pages/logistics/trackpi";
// import TrackReverse from "../../pages/logistics/trackreverse";
// import CarrierQuotations from "../../pages/logistics/quotations";
// import CarrierQuotationsPage from "../../pages/logistics/quotations/quotation";

// //Feeds
// import Feeds from "../../pages/feeds/feeds";
// import Ctes from "../../pages/feeds/ctes";

// //Consumers
// import Consumers from "../../pages/consumers";
// import Consumer from "../../pages/consumers/consumer";
// import BankSplip from "../../pages/consumers/bankslip";
// import GiftCard from "../../pages/consumers/giftcard";

// //Hub
// import HubOffers from "../../pages/hub/offers";
// import Offer from "../../pages/hub/offers/offer";
// import HubBrands from "../../pages/hub/brands";
// import HubCategory from "../../pages/hub/categories";
// import HubCompanySales from "../../pages/hub/companysales";
// import HubCarriers from "../../pages/hub/carriers";
// import HubOrders from "../../pages/hub/orders";
// import HubOrderPayments from "../../pages/hub/orders/components/HubOrderPayments";
// import HubPayments from "../../pages/hub/payments";
// import HubWarehouse from "../../pages/hub/warehouse";
// import Logs from "../../pages/hub/logs";
// import QuestionAnswer from "../../pages/hub/questionanswer";
// import HubMessages from "../../pages/hub/messages";

// //Reports
// import Reports from "../../pages/reports";

// //Reports
// import Error from "../../pages/error";

// //Company
// import CompanyView from "../../pages/companies/company";
// import Companies from "../../pages/companies";
// import CompanyParameter from "../../pages/companies/companyparameter";
// import Parameter from "../../pages/companies/companyparameter/parameter";
// import Privileges from "../../pages/companies/privileges";
// import Privilege from "../../pages/companies/privileges/privilege";
// import TypeStatusOrder from "../../pages/companies/typestatusorder";
// import CompanySalesPolicy from "../../pages/companies/companysalespolicy";
// import TypeStatusSefaz from "../../pages/companies/typestatussefaz";
// import Users from "../../pages/companies/users";
// import Commissions from "../../pages/companies/commissions";
// import CarrierModal from "../../pages/companies/carriermodal";
// import Webhook from "../../pages/companies/webhook";

// //Company
// import Seller from "../../pages/sellers/seller";
// import Sellers from "../../pages/sellers";

// //Dashboard
// import DashboardOrders from "../../pages/dashboards";

// //op
// import OPListSkus from "../../pages/op/skus";
// import OPLine from "../../pages/op/line";
// import OPList from "../../pages/op/oplist";
// import OPView from "../../pages/op/oplist/opview";

// //Orçamento
// import Budgets from "../../pages/budgets";
// import Budget from "../../pages/budgets/order";

import { Modules } from "../../new/modules";

export default function ComponentView(props) {
    const view = props.view;
    const paramsI = props.paramsI;
    const paramsII = props.paramsII;
    const paramsIII = props.paramsIII;
    const paramsIV = props.paramsIV;
    const paramsV = props.paramsV;

    return (
        <Modules
            tag={view}
            oldKey={view}
            oldParams={{
                paramsI,
                paramsII,
                paramsIII,
                paramsIV,
                paramsV,
            }}
        />
    );

    // var RESPONSE;
    // switch (view) {
    //     case "OrderItems":
    //         RESPONSE = <OrderItems></OrderItems>;
    //         break;

    //     case "BuyOrdeItems":
    //         RESPONSE = <BuyOrdeItems></BuyOrdeItems>;
    //         break;

    //     case "OrderPayments":
    //         RESPONSE = <OrderPayments></OrderPayments>;
    //         break;

    //     case "OrderEvents":
    //         RESPONSE = <OrderEvents></OrderEvents>;
    //         break;

    //     case "OrderFiles":
    //         RESPONSE = <OrderFiles></OrderFiles>;
    //         break;

    //     case "OrderPackages":
    //         RESPONSE = <OrderPackages></OrderPackages>;
    //         break;

    //     case "company":
    //         RESPONSE = (
    //             <Companies
    //                 URLSearchParams={paramsI.URLSearchParams}></Companies>
    //         );
    //         break;

    //     case "companyview":
    //         RESPONSE = (
    //             <CompanyView
    //                 URLSearchParams={paramsI.URLSearchParams}></CompanyView>
    //         );
    //         break;

    //     case "sellers":
    //         RESPONSE = (
    //             <Sellers URLSearchParams={paramsI.URLSearchParams}></Sellers>
    //         );
    //         break;

    //     case "seller":
    //         RESPONSE = (
    //             <Seller URLSearchParams={paramsI.URLSearchParams}></Seller>
    //         );
    //         break;

    //     case "privileges":
    //         RESPONSE = (
    //             <Privileges
    //                 URLSearchParams={paramsI.URLSearchParams}></Privileges>
    //         );
    //         break;

    //     case "privilege":
    //         RESPONSE = (
    //             <Privilege
    //                 URLSearchParams={paramsI.URLSearchParams}></Privilege>
    //         );
    //         break;

    //     case "companyparameter":
    //         RESPONSE = (
    //             <CompanyParameter
    //                 URLSearchParams={
    //                     paramsI.URLSearchParams
    //                 }></CompanyParameter>
    //         );
    //         break;

    //     case "parameter":
    //         RESPONSE = (
    //             <Parameter
    //                 URLSearchParams={paramsI.URLSearchParams}></Parameter>
    //         );
    //         break;
    //     // trocar o componente para renderizar.
    //     case "typestatusorder":
    //         RESPONSE = (
    //             <TypeStatusOrder
    //                 URLSearchParams={paramsI.URLSearchParams}></TypeStatusOrder>
    //         );
    //         // RESPONSE = <CompanySalesPolicy URLSearchParams={paramsI.URLSearchParams}></CompanySalesPolicy>
    //         break;

    //     case "typestatussefaz":
    //         RESPONSE = (
    //             <TypeStatusSefaz
    //                 URLSearchParams={paramsI.URLSearchParams}></TypeStatusSefaz>
    //         );
    //         break;

    //     case "users":
    //         RESPONSE = (
    //             <Users URLSearchParams={paramsI.URLSearchParams}></Users>
    //         );
    //         break;

    //     case "hub/skuoffer":
    //         RESPONSE = (
    //             <HubOffers
    //                 URLSearchParams={paramsI.URLSearchParams}></HubOffers>
    //         );
    //         break;

    //     case "offer":
    //         RESPONSE = (
    //             <Offer URLSearchParams={paramsI.URLSearchParams}></Offer>
    //         );
    //         break;

    //     case "hubbrand":
    //         RESPONSE = (
    //             <HubBrands
    //                 URLSearchParams={paramsI.URLSearchParams}></HubBrands>
    //         );
    //         break;

    //     case "hubcategory":
    //         RESPONSE = (
    //             <HubCategory
    //                 URLSearchParams={paramsI.URLSearchParams}></HubCategory>
    //         );
    //         break;

    //     case "hubwarehouse":
    //         RESPONSE = (
    //             <HubWarehouse
    //                 URLSearchParams={paramsI.URLSearchParams}></HubWarehouse>
    //         );
    //         break;

    //     case "hubpayment":
    //         RESPONSE = (
    //             <HubPayments
    //                 URLSearchParams={paramsI.URLSearchParams}></HubPayments>
    //         );
    //         break;

    //     case "hub/orders":
    //         RESPONSE = (
    //             <HubOrders
    //                 URLSearchParams={paramsI.URLSearchParams}></HubOrders>
    //         );
    //         break;

    //     case "hubOrderPayments":
    //         RESPONSE = <HubOrderPayments data={paramsII}></HubOrderPayments>;
    //         break;

    //     case "hub/skulog":
    //         RESPONSE = <Logs URLSearchParams={paramsI.URLSearchParams}></Logs>;
    //         break;

    //     case "hub/productquestionanswer":
    //         RESPONSE = (
    //             <QuestionAnswer
    //                 URLSearchParams={paramsI.URLSearchParams}></QuestionAnswer>
    //         );
    //         break;

    //     case "hubcarrier":
    //         RESPONSE = (
    //             <HubCarriers
    //                 URLSearchParams={paramsI.URLSearchParams}></HubCarriers>
    //         );
    //         break;

    //     case "consumers":
    //         RESPONSE = (
    //             <Consumers
    //                 URLSearchParams={paramsI.URLSearchParams}></Consumers>
    //         );
    //         break;

    //     case "consumerbankslip":
    //         RESPONSE = (
    //             <BankSplip
    //                 URLSearchParams={paramsI.URLSearchParams}></BankSplip>
    //         );
    //         break;

    //     case "consumergiftcard":
    //         RESPONSE = (
    //             <GiftCard URLSearchParams={paramsI.URLSearchParams}></GiftCard>
    //         );
    //         break;

    //     case "consumer":
    //         RESPONSE = (
    //             <Consumer URLSearchParams={paramsI.URLSearchParams}></Consumer>
    //         );
    //         break;

    //     case "typedocument":
    //         RESPONSE = (
    //             <TypeDocument
    //                 URLSearchParams={paramsI.URLSearchParams}></TypeDocument>
    //         );
    //         break;

    //     case "typepayment":
    //         RESPONSE = (
    //             <TypePayment
    //                 URLSearchParams={paramsI.URLSearchParams}></TypePayment>
    //         );
    //         break;

    //     case "typeaccountcategory":
    //         RESPONSE = (
    //             <TypeAccountCategory
    //                 URLSearchParams={
    //                     paramsI.URLSearchParams
    //                 }></TypeAccountCategory>
    //         );
    //         break;

    //     case "typeorder":
    //         RESPONSE = (
    //             <TypeOrder
    //                 URLSearchParams={paramsI.URLSearchParams}></TypeOrder>
    //         );
    //         break;

    //     case "typeaccount":
    //         RESPONSE = (
    //             <TypeAccount
    //                 URLSearchParams={paramsI.URLSearchParams}></TypeAccount>
    //         );
    //         break;

    //     case "reports":
    //         RESPONSE = (
    //             <Reports URLSearchParams={paramsI.URLSearchParams}></Reports>
    //         );
    //         break;

    //     case "packing":
    //         RESPONSE = (
    //             <Packing URLSearchParams={paramsI.URLSearchParams}></Packing>
    //         );
    //         break;
    //     case "pickinglistprint":
    //         RESPONSE = (
    //             <PickingListPrint
    //                 URLSearchParams={
    //                     paramsI.URLSearchParams
    //                 }></PickingListPrint>
    //         );
    //         break;
    //     case "collectionlist":
    //         RESPONSE = (
    //             <CollectionList
    //                 URLSearchParams={paramsI.URLSearchParams}></CollectionList>
    //         );
    //         break;
    //     case "packages":
    //         RESPONSE = (
    //             <Packages URLSearchParams={paramsI.URLSearchParams}></Packages>
    //         );
    //         break;
    //     case "feeds":
    //         RESPONSE = (
    //             <Feeds URLSearchParams={paramsI.URLSearchParams}></Feeds>
    //         );
    //         break;

    //     case "ctes":
    //         RESPONSE = <Ctes URLSearchParams={paramsI.URLSearchParams}></Ctes>;
    //         break;

    //     case "supplierview":
    //         RESPONSE = (
    //             <SupplierView
    //                 URLSearchParams={paramsI.URLSearchParams}></SupplierView>
    //         );
    //         break;

    //     case "supplier":
    //         RESPONSE = (
    //             <Suppliers
    //                 URLSearchParams={paramsI.URLSearchParams}></Suppliers>
    //         );
    //         break;

    //     case "accountsreceivable":
    //         RESPONSE = (
    //             <AccountsReceivable
    //                 URLSearchParams={
    //                     paramsI.URLSearchParams
    //                 }></AccountsReceivable>
    //         );
    //         break;

    //     case "accountreceivable":
    //         RESPONSE = (
    //             <AccountReceivable
    //                 URLSearchParams={
    //                     paramsI.URLSearchParams
    //                 }></AccountReceivable>
    //         );
    //         break;

    //     case "suppliercateogry":
    //         RESPONSE = (
    //             <SupplierCategories
    //                 URLSearchParams={
    //                     paramsI.URLSearchParams
    //                 }></SupplierCategories>
    //         );
    //         break;

    //     case "accounts/bank-account":
    //         RESPONSE = <Bank URLSearchParams={paramsI.URLSearchParams}></Bank>;
    //         break;

    //     case "accountspayable":
    //         RESPONSE = (
    //             <AccountsPayable
    //                 URLSearchParams={paramsI.URLSearchParams}></AccountsPayable>
    //         );
    //         break;

    //     case "accountpayable":
    //         RESPONSE = (
    //             <AccountPayable
    //                 URLSearchParams={paramsI.URLSearchParams}></AccountPayable>
    //         );
    //         break;

    //     case "skus":
    //         RESPONSE = <Skus URLSearchParams={paramsI.URLSearchParams}></Skus>;
    //         break;

    //     case "myskus":
    //         RESPONSE = (
    //             <MySkus URLSearchParams={paramsI.URLSearchParams}></MySkus>
    //         );
    //         break;

    //     case "NewSku":
    //         RESPONSE = (
    //             <NewSku
    //                 OnlyView={paramsI}
    //                 DataContext={paramsII}
    //                 ButtonConfig={paramsIII}
    //                 IndexContext={paramsIV}
    //                 OwnProduction={paramsV}></NewSku>
    //         );
    //         break;

    //     case "sku":
    //         RESPONSE = <Sku URLSearchParams={paramsI.URLSearchParams}></Sku>;
    //         break;

    //     case "skustock":
    //         RESPONSE = (
    //             <SkuStock URLSearchParams={paramsI.URLSearchParams}></SkuStock>
    //         );
    //         break;

    //     case "brands":
    //         RESPONSE = (
    //             <Brands URLSearchParams={paramsI.URLSearchParams}></Brands>
    //         );
    //         break;

    //     case "brand":
    //         RESPONSE = (
    //             <Brand URLSearchParams={paramsI.URLSearchParams}></Brand>
    //         );
    //         break;

    //     case "newBrand":
    //         RESPONSE = <NewBrand ListPage={paramsI}></NewBrand>;
    //         break;

    //     case "buyorder":
    //         RESPONSE = (
    //             <BuyOrder URLSearchParams={paramsI.URLSearchParams}></BuyOrder>
    //         );
    //         break;

    //     case "buyorderanalysis":
    //         RESPONSE = (
    //             <BuyOrderAnalysis
    //                 URLSearchParams={
    //                     paramsI.URLSearchParams
    //                 }></BuyOrderAnalysis>
    //         );
    //         break;

    //     case "skussuppliers":
    //         RESPONSE = (
    //             <SkusSuppliers
    //                 URLSearchParams={paramsI.URLSearchParams}></SkusSuppliers>
    //         );
    //         break;

    //     case "inventory":
    //         RESPONSE = (
    //             <Inventory
    //                 URLSearchParams={paramsI.URLSearchParams}></Inventory>
    //         );
    //         break;

    //     case "categories":
    //         RESPONSE = (
    //             <Categories
    //                 URLSearchParams={paramsI.URLSearchParams}></Categories>
    //         );
    //         break;

    //     case "purchases":
    //         RESPONSE = (
    //             <Purchases
    //                 URLSearchParams={paramsI.URLSearchParams}></Purchases>
    //         );
    //         break;

    //     case "conference":
    //         RESPONSE = <Conference></Conference>;
    //         break;

    //     case "warehouse":
    //         RESPONSE = (
    //             <Warehouse
    //                 URLSearchParams={paramsI.URLSearchParams}></Warehouse>
    //         );
    //         break;

    //     case "skusellinganalysis":
    //         RESPONSE = (
    //             <SkuSellingAnalysis
    //                 URLSearchParams={
    //                     paramsI.URLSearchParams
    //                 }></SkuSellingAnalysis>
    //         );
    //         break;

    //     case "typeskuvariation":
    //         RESPONSE = (
    //             <TypeSkuVariation
    //                 URLSearchParams={
    //                     paramsI.URLSearchParams
    //                 }></TypeSkuVariation>
    //         );
    //         break;

    //     case "taxes":
    //         RESPONSE = (
    //             <Taxes URLSearchParams={paramsI.URLSearchParams}></Taxes>
    //         );
    //         break;

    //     case "tax":
    //         RESPONSE = <Tax URLSearchParams={paramsI.URLSearchParams}></Tax>;
    //         break;

    //     case "skulocation":
    //         RESPONSE = (
    //             <SkuLocation
    //                 URLSearchParams={paramsI.URLSearchParams}></SkuLocation>
    //         );
    //         break;

    //     case "skumovement":
    //         RESPONSE = (
    //             <SkuMovement
    //                 URLSearchParams={paramsI.URLSearchParams}></SkuMovement>
    //         );
    //         break;

    //     case "skukardex":
    //         RESPONSE = (
    //             <SkuKardex
    //                 URLSearchParams={paramsI.URLSearchParams}></SkuKardex>
    //         );
    //         break;

    //     case "skusbatch":
    //         RESPONSE = (
    //             <SkusBacth
    //                 URLSearchParams={paramsI.URLSearchParams}></SkusBacth>
    //         );
    //         break;

    //     case "skupricing":
    //         RESPONSE = (
    //             <SkuPricing
    //                 URLSearchParams={paramsI.URLSearchParams}></SkuPricing>
    //         );
    //         break;

    //     case "skuvariation":
    //         RESPONSE = (
    //             <SkuVariation
    //                 URLSearchParams={paramsI.URLSearchParams}></SkuVariation>
    //         );
    //         break;

    //     case "subscriptionpromotional":
    //         RESPONSE = (
    //             <Promotions
    //                 URLSearchParams={paramsI.URLSearchParams}></Promotions>
    //         );
    //         break;

    //     case "subscription":
    //         RESPONSE = (
    //             <Subscriptions
    //                 URLSearchParams={paramsI.URLSearchParams}></Subscriptions>
    //         );
    //         break;

    //     case "subscriptionsheet":
    //         RESPONSE = (
    //             <Subscription
    //                 URLSearchParams={paramsI.URLSearchParams}></Subscription>
    //         );
    //         break;

    //     case "subscriptioncharge":
    //         RESPONSE = (
    //             <Changes URLSearchParams={paramsI.URLSearchParams}></Changes>
    //         );
    //         break;

    //     case "subscriptionplan":
    //         RESPONSE = (
    //             <Plans URLSearchParams={paramsI.URLSearchParams}></Plans>
    //         );
    //         break;

    //     case "subscriptionplansheet":
    //         RESPONSE = <Plan URLSearchParams={paramsI.URLSearchParams}></Plan>;
    //         break;

    //     case "subscriptionbill":
    //         RESPONSE = (
    //             <Bills URLSearchParams={paramsI.URLSearchParams}></Bills>
    //         );
    //         break;

    //     case "subscriptionbillsheet":
    //         RESPONSE = <Bill URLSearchParams={paramsI.URLSearchParams}></Bill>;
    //         break;

    //     case "actionsBillsList":
    //         RESPONSE = (
    //             <ActionsBills
    //                 dataAction={paramsI}
    //                 listPageSelectedRows={paramsII}
    //                 IndexContext={paramsIII}></ActionsBills>
    //         );
    //         break;

    //     case "order":
    //         RESPONSE = (
    //             <Order URLSearchParams={paramsI.URLSearchParams}></Order>
    //         );
    //         break;

    //     case "orders":
    //         RESPONSE = (
    //             <Orders URLSearchParams={paramsI.URLSearchParams}></Orders>
    //         );
    //         break;

    //     case "budgets":
    //         RESPONSE = (
    //             <Budgets URLSearchParams={paramsI.URLSearchParams}></Budgets>
    //         );
    //         break;

    //     case "budget":
    //         RESPONSE = (
    //             <Budget URLSearchParams={paramsI.URLSearchParams}></Budget>
    //         );
    //         break;

    //     case "ordersActionList":
    //         RESPONSE = (
    //             <OrderActionList
    //                 dataAction={paramsI}
    //                 listPageSelectedRows={paramsII}
    //                 IndexContext={paramsIII}></OrderActionList>
    //         );
    //         break;

    //     case "purchaseOrder":
    //         RESPONSE = <PurchaseOrder dataContext={paramsIII}></PurchaseOrder>;
    //         break;

    //     case "PurchaseItems":
    //         RESPONSE = <PurchaseItems></PurchaseItems>;
    //         break;

    //     case "PurchasePayments":
    //         RESPONSE = <PurchasePayments></PurchasePayments>;
    //         break;

    //     case "newOrder":
    //         RESPONSE = (
    //             <NewOrder
    //                 dataContext={paramsI}
    //                 Budget={paramsII}
    //                 Invoice={paramsIII}></NewOrder>
    //         );
    //         break;

    //     case "purchase":
    //         RESPONSE = (
    //             <Purchase URLSearchParams={paramsI.URLSearchParams}></Purchase>
    //         );
    //         break;

    //     case "gnrerule":
    //         RESPONSE = (
    //             <GnreRule URLSearchParams={paramsI.URLSearchParams}></GnreRule>
    //         );
    //         break;

    //     case "gnre":
    //         RESPONSE = <Gnre URLSearchParams={paramsI.URLSearchParams}></Gnre>;
    //         break;

    //     case "carriers":
    //         RESPONSE = (
    //             <Carriers URLSearchParams={paramsI.URLSearchParams}></Carriers>
    //         );
    //         break;

    //     case "pickinglist":
    //         RESPONSE = (
    //             <Outbound
    //                 URLSearchParams={
    //                     "?IDStatusOrder=1&StatusOrder=Fechado&component=pickinglist&title=Outbound"
    //                 }></Outbound>
    //         );
    //         break;

    //     case "oplogistic":
    //         RESPONSE = (
    //             <OpLogistic
    //                 URLSearchParams={paramsI.URLSearchParams}></OpLogistic>
    //         );
    //         break;

    //     case "oplogistics":
    //         RESPONSE = (
    //             <OpLogistics
    //                 URLSearchParams={paramsI.URLSearchParams}></OpLogistics>
    //         );
    //         break;

    //     case "packageview":
    //         RESPONSE = (
    //             <PackageView
    //                 URLSearchParams={paramsI.URLSearchParams}></PackageView>
    //         );
    //         break;

    //     case "comments":
    //         RESPONSE = (
    //             <Comments URLSearchParams={paramsI.URLSearchParams}></Comments>
    //         );
    //         break;

    //     case "trackingevents":
    //         RESPONSE = (
    //             <TrackingEvents
    //                 URLSearchParams={paramsI.URLSearchParams}></TrackingEvents>
    //         );
    //         break;

    //     case "invoice":
    //         RESPONSE = (
    //             <Invoice URLSearchParams={paramsI.URLSearchParams}></Invoice>
    //         );
    //         break;

    //     case "buyorde":
    //         RESPONSE = (
    //             <BuyOrde URLSearchParams={paramsI.URLSearchParams}></BuyOrde>
    //         );
    //         break;

    //     case "dashboard":
    //         RESPONSE = (
    //             <DashboardOrders
    //                 URLSearchParams={paramsI.URLSearchParams}></DashboardOrders>
    //         );
    //         break;
    //     case "category":
    //         RESPONSE = (
    //             <Category URLSearchParams={paramsI.URLSearchParams}></Category>
    //         );
    //         break;
    //     case "salespolicy":
    //         RESPONSE = (
    //             <CompanySalesPolicy
    //                 URLSearchParams={
    //                     paramsI.URLSearchParams
    //                 }></CompanySalesPolicy>
    //         );
    //         break;

    //     case "trackpi":
    //         RESPONSE = (
    //             <TrackPi URLSearchParams={paramsI.URLSearchParams}></TrackPi>
    //         );
    //         break;
    //     case "hubsalespolicy":
    //         RESPONSE = (
    //             <HubCompanySales
    //                 URLSearchParams={paramsI.URLSearchParams}></HubCompanySales>
    //         );
    //         break;

    //     case "trackreverse":
    //         RESPONSE = (
    //             <TrackReverse
    //                 URLSearchParams={paramsI.URLSearchParams}></TrackReverse>
    //         );
    //         break;

    //     case "commission":
    //         RESPONSE = (
    //             <Commissions
    //                 URLSearchParams={paramsI.URLSearchParams}></Commissions>
    //         );
    //         break;

    //     case "hub/messages":
    //         RESPONSE = (
    //             <HubMessages
    //                 URLSearchParams={paramsI.URLSearchParams}></HubMessages>
    //         );
    //         break;

    //     case "inventoryItem":
    //         RESPONSE = (
    //             <InventoryItem
    //                 URLSearchParams={paramsI.URLSearchParams}></InventoryItem>
    //         );
    //         break;

    //     case "sku/inventory":
    //         RESPONSE = (
    //             <NewInventory
    //                 URLSearchParams={paramsI.URLSearchParams}></NewInventory>
    //         );
    //         break;
    //     case "skusop":
    //         RESPONSE = (
    //             <OPListSkus
    //                 URLSearchParams={paramsI.URLSearchParams}></OPListSkus>
    //         );
    //         break;

    //     case "opview":
    //         RESPONSE = (
    //             <OPView URLSearchParams={paramsI.URLSearchParams}></OPView>
    //         );
    //         break;

    //     case "buyop":
    //         RESPONSE = (
    //             <OPList URLSearchParams={paramsI.URLSearchParams}></OPList>
    //         );
    //         break;

    //     case "lineop":
    //         RESPONSE = (
    //             <OPLine URLSearchParams={paramsI.URLSearchParams}></OPLine>
    //         );
    //         break;

    //     case "carrier/quotation":
    //         RESPONSE = (
    //             <CarrierQuotations
    //                 URLSearchParams={
    //                     paramsI.URLSearchParams
    //                 }></CarrierQuotations>
    //         );
    //         break;

    //     case "quotation":
    //         RESPONSE = (
    //             <CarrierQuotationsPage
    //                 URLSearchParams={
    //                     paramsI.URLSearchParams
    //                 }></CarrierQuotationsPage>
    //         );
    //         break;

    //     case "carrier/modal":
    //         RESPONSE = (
    //             <CarrierModal
    //                 URLSearchParams={paramsI.URLSearchParams}></CarrierModal>
    //         );
    //         break;

    //     case "webhook":
    //         RESPONSE = (
    //             <Webhook URLSearchParams={paramsI.URLSearchParams}></Webhook>
    //         );
    //         break;

    //     case "pricer":
    //         RESPONSE = (
    //             <Pricer URLSearchParams={paramsI.URLSearchParams}></Pricer>
    //         );
    //         break;

    //     default:
    //         RESPONSE = (
    //             <Error URLSearchParams={paramsI.URLSearchParams}></Error>
    //         );
    // }

    // return RESPONSE;
}
