import type { ReactNode } from "react";

import { forwardRef } from "react";

import { classes } from "@utils/styles/tailwind/v4";

export interface ModalFooterProps {
    className?: string;
    children?: ReactNode;
}

const ModalFooter = forwardRef<HTMLDivElement, ModalFooterProps>(
    function ModalFooter({ className, children }, forwarded) {
        return (
            <div
                ref={forwarded}
                className={classes`flex-1 flex flex-col pt-16 before:flex-1 ${className}`}>
                {children}
            </div>
        );
    },
);

export default ModalFooter;
