import type { ComponentType, FC } from "react";

import { useContext, useEffect, useRef } from "react";

import { ModalManagerContext } from "./interface";
import type {
    ModalManagerComponent,
    ModalManagerInjectedProps,
} from "./interface";
import { commonModals } from "./relations";
import type { ModalCommonTags } from "./relations";

type ModalRelation = {
    [key: string]: ModalManagerComponent<any>;
} & Partial<Record<ModalCommonTags, never>>;

type ParseKeys<Relation extends ModalRelation> =
    | Exclude<keyof Relation, ModalCommonTags | number | symbol>
    | ModalCommonTags;

type MapProps<
    Relation extends ModalRelation,
    Key extends ParseKeys<Relation>,
> = {
    [K in ParseKeys<Relation>]: Relation[K] extends ComponentType<infer Props>
        ? Props extends ModalManagerInjectedProps & infer R
            ? R
            : Record<string, never>
        : K extends keyof typeof commonModals
        ? (typeof commonModals)[K] extends ComponentType<infer Props>
            ? Props extends ModalManagerInjectedProps & infer R
                ? R
                : Record<string, never>
            : Record<string, never>
        : Record<string, never>;
}[Key];

type MapEntries<Relation extends ModalRelation> = {
    [K in ParseKeys<Relation>]: { tag: K; props?: MapProps<Relation, K> };
}[ParseKeys<Relation>] extends infer R
    ? R
    : never;

interface UseModalManagerReturn<
    Relation extends ModalRelation,
    OverWrite extends ModalRelation = NonNullable<unknown>,
> extends Pick<ModalManagerContext, "clear" | "current" | "pop" | "stack"> {
    push: <Tag extends ParseKeys<Relation & OverWrite>>(
        tag: Tag,
        props?: MapProps<Relation & OverWrite, Tag>,
    ) => [string, () => void];
    set: (entries: MapEntries<Relation & OverWrite>[]) => void;
}

export function useModalManager<
    Relation extends ModalRelation,
    OverWrite extends ModalRelation = NonNullable<unknown>,
>(relation: Relation): UseModalManagerReturn<Relation, OverWrite> {
    const { appendPool, clear, current, pop, push, removePool, set, stack } =
        useContext(ModalManagerContext);

    const relationRef = useRef(relation);

    useEffect(() => {
        const tags: string[] = [];

        for (const tag in relationRef.current) {
            const Component = relationRef.current[tag];
            appendPool(tag, Component);
            tags.push(tag);
        }

        return () => {
            for (const tag of tags) {
                removePool(tag);
            }
        };
    }, [appendPool, removePool]);

    return {
        clear,
        current,
        pop,
        push,
        set: set as (entries: MapEntries<Relation & OverWrite>[]) => void,
        stack,
    };
}

export function withModalManager<
    Relation extends ModalRelation,
    Props extends JSX.IntrinsicAttributes,
>(Component: ComponentType<Props>, relation: Relation): FC<Props> {
    const Wrapped: FC<Props> = (props) => {
        const modalManager = useModalManager(relation);
        return <Component {...props} _modalManager={modalManager} />;
    };
    Wrapped.displayName = `withModalManager(${Component.displayName})`;
    return Wrapped;
}
