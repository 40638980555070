import { legacy_createStore } from "redux";
import type { Action, PreloadedStateShapeFromReducersMapObject } from "redux";
import { persistStore } from "redux-persist";

import { modules2Reducer } from "./helpers";
import type { DynamicStore } from "./interface";

import type { DynamicStoreEnhancer } from "../middlewares/interface";
import { formatModuleName } from "../modules/helpers";
import type { ModuleData } from "../modules/interface";
import { purgePersistency } from "../persistency";

export function createDynamicStore<
    S,
    A extends Action<any> = Action<any>,
    Ext extends Record<string, any> = NonNullable<unknown>,
    StateExt extends Record<string, any> = NonNullable<unknown>,
>(
    initModules: ModuleData[],
    enhancer?: DynamicStoreEnhancer<Ext, StateExt>,
): DynamicStore<S & StateExt, A, Ext>;
export function createDynamicStore<
    S,
    A extends Action<any>,
    Ext extends Record<string, any> = NonNullable<unknown>,
    StateExt extends Record<string, any> = NonNullable<unknown>,
>(
    initModules: ModuleData[],
    preloadedState?: PreloadedStateShapeFromReducersMapObject<S>,
    enhancer?: DynamicStoreEnhancer<Ext, StateExt>,
): DynamicStore<S & StateExt, A, Ext>;
export function createDynamicStore<
    S,
    A extends Action<any>,
    Ext extends Record<string, any> = NonNullable<unknown>,
    StateExt extends Record<string, any> = NonNullable<unknown>,
>(
    initModules: ModuleData[],
    preloadedState?:
        | PreloadedStateShapeFromReducersMapObject<S>
        | DynamicStoreEnhancer<Ext, StateExt>,
    enhancer?: DynamicStoreEnhancer<Ext, StateExt>,
): DynamicStore<S & StateExt, A, Ext> {
    if (
        typeof preloadedState === "function" &&
        typeof enhancer === "function"
    ) {
        throw new Error("Multiple enhancers not supported");
    }

    if (
        typeof preloadedState === "function" &&
        typeof enhancer === "undefined"
    ) {
        enhancer = preloadedState as DynamicStoreEnhancer<Ext, StateExt>;
        preloadedState = undefined;
    }

    if (typeof enhancer !== "undefined") {
        if (typeof enhancer !== "function") {
            throw new Error("Expected the enhancer to be a function.");
        }

        return enhancer(createDynamicStore)(
            initModules,
            preloadedState as
                | PreloadedStateShapeFromReducersMapObject<S>
                | undefined,
        ) as unknown as DynamicStore<S & StateExt, A, Ext>;
    }

    const modules: ModuleData[] = initModules;

    const store = legacy_createStore(
        modules2Reducer(modules),
        preloadedState,
    ) satisfies DynamicStore<S & StateExt, A, Ext>;

    store.modules = modules;

    const temp = { enhancer: undefined, manualPersist: true };
    store.persistor = persistStore(store, temp);
    store.initPersistor = () => {
        store.persistor.persist();
    };

    const updateModules = () => {
        const newReducer = modules2Reducer(modules);
        store.replaceReducer(newReducer);
        store.persistor = persistStore(store);
    };

    store.addModules = (mds) => {
        let changed = false;
        mds.forEach((md) => {
            const idx = modules.findIndex(({ name }) => name === md.name);
            if (idx === -1) {
                changed = true;
                modules.push({ ...md, updatedAt: md.updatedAt || Date.now() });
            }
        });
        if (changed) updateModules();
    };

    store.removeModules = (mds, force = false) => {
        let changed = false;
        mds.forEach((md) => {
            const name =
                md.name || formatModuleName({ id: md.id || "", hash: md.hash });
            const idx = modules.findIndex(
                (md2) => md2.name === name && (force || !md2.fixed),
            );
            if (idx !== -1) {
                changed = true;
                purgePersistency(modules[idx]);
                modules.splice(idx, 1);
            }
        });
        if (changed) updateModules();
    };

    store.toggleModule = (md, force = false) => {
        const idx = modules.findIndex(({ name }) => name === md.name);
        if (idx === -1) {
            modules.push({ ...md, updatedAt: md.updatedAt || Date.now() });
        } else if (force || !md.fixed) {
            purgePersistency(md);
            modules.splice(idx, 1);
        }
        updateModules();
    };

    store.updateModule = (md, newData) => {
        const name =
            md.name || formatModuleName({ id: md.id || "", hash: md.hash });
        const idx = modules.findIndex((md2) => md2.name === name);
        if (idx !== -1) {
            modules[idx] = { ...modules[idx], ...newData };
            updateModules();
        }
    };

    return store;
}
