import { Modal, Row, Col, Button } from "antd";
import { useState } from "react";
import { ModalSize } from "../../util/ModalSize";
// import ComponentView from "./ComponentView";
import { Icon } from "./Icon";

import { Modules } from "../../new/modules";

export default function ModalView(props) {
    const {
        title,
        view,
        paramsI,
        paramsII,
        paramsIII,
        paramsIV,
        paramsV,
        closeModal,
    } = props;
    const [toggleModal, setToggleModal] = useState(false);

    const modalSize = ModalSize("large");

    function ToggleModal(action) {
        if (action === "open") {
            setToggleModal(true);
        } else {
            closeModal && closeModal();
            setToggleModal(false);
        }
    }

    return (
        <>
            <Button
                {...props}
                onClick={() => ToggleModal("open")}
                size="small"
                type="dashed"
                shape="circle"
                title="Visualizar"
                icon={<Icon name="EyeOutlined" />}
            />

            <Modal
                title={title}
                maskClosable={false}
                open={toggleModal}
                centered
                bodyStyle={{ height: modalSize.height, overflow: "auto" }}
                width={modalSize.width}
                onCancel={() => ToggleModal()}
                footer={[
                    <Button onClick={() => ToggleModal()}>Fechar</Button>,
                ]}>
                <Row>
                    <Col sm={24} xs={24} md={24} lg={24}>
                        <Modules
                            tag={view}
                            mainKey="modal"
                            oldParams={{
                                paramsI,
                                paramsII,
                                paramsIII,
                                paramsIV,
                                paramsV,
                            }}
                        />
                        {/* <ComponentView
                            view={view}
                            paramsI={paramsI}
                            paramsII={paramsII}
                            paramsIII={paramsIII}
                            paramsIV={paramsIV}
                            paramsV={paramsV}></ComponentView> */}
                    </Col>
                </Row>
            </Modal>
        </>
    );
}
