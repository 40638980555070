import type { ReactNode } from "react";

import { forwardRef } from "react";

import { useModalContext } from "./helpers";

import { classes } from "@utils/styles/tailwind/v4";

interface ModalBodyProps {
    className?: string;
    children?: ReactNode;
}

const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>(function ModalBody(
    { className, children },
    forwarded,
) {
    const { aria } = useModalContext();
    return (
        <div
            ref={forwarded}
            id={aria.description}
            className={classes`overflow-x-hidden overflow-y-auto ${className}`}>
            {children}
        </div>
    );
});

export default ModalBody;
