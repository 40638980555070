import type { FC } from "react";

import { Suspense, useMemo } from "react";

import { loadComponent } from "./helpers";
import { moduleRelation } from "./relations";
import type { ModuleInjectedProps, ModuleSpecs } from "./interface";

interface ModulesProps {
    mainKey: string;
    params?: Record<string, any>;
    tag: keyof typeof moduleRelation;
    oldKey?: string;
    URLSearchParams?: string;
    oldParams?: {
        paramsI?: any;
        paramsII?: any;
        paramsIII?: any;
        paramsIV?: any;
        paramsV?: any;
    };
}

export const Modules: FC<ModulesProps> = ({
    tag,
    mainKey,
    params,
    oldKey,
    URLSearchParams,
    oldParams: _oldParams,
}) => {
    const module = useMemo<ModuleSpecs>(
        () => moduleRelation[tag as keyof typeof moduleRelation],
        [tag],
    );

    const Component = useMemo(() => module && loadComponent(module), [module]);

    if (!Component) return null;

    const props: ModuleInjectedProps["_moduleManager"] = {
        tag,
        key: mainKey,
        oldKey,
        params: params ?? {},
    };

    const search = _oldParams?.paramsI?.URLSearchParams || URLSearchParams;
    const oldParams = module.oldParams?.(_oldParams) || _oldParams;

    return (
        <Suspense fallback={null}>
            <Component
                _moduleManager={props}
                URLSearchParams={search}
                {...oldParams}
            />
        </Suspense>
    );
};
