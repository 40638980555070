import { combineReducers } from "redux";
import type { Reducer } from "redux";
import { createMigrate, persistReducer, REHYDRATE } from "redux-persist";
import type { MigrationManifest, PersistedState } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { internalId } from "./interface";
import type { ModuleFetcher, InternalPersistency } from "./interface";

import { DynamicMiddleware } from "../middlewares/interface";

import { splitModuleName } from "../modules/helpers";
import type { ModuleData } from "../modules/interface";

import { processPersistency } from "../persistency";

type State_1 = NonNullable<PersistedState> & { [internalId]: string[] };
type State0 = NonNullable<PersistedState> & {
    [internalId]: InternalPersistency;
};

const migrations = {
    0: (state: State_1 | undefined) => {
        console.log(state);
        const internalState = state?.[internalId];
        return {
            _persist: { version: 0, rehydrated: false },
            [internalId]:
                internalState?.map((name) => ({
                    name,
                    updatedAt: null,
                })) || [],
        } satisfies State0;
    },
} as MigrationManifest;

export function modules2Reducer(modules: ModuleData[]): Reducer {
    const newReducer = combineReducers({
        [internalId]: (): InternalPersistency =>
            modules.map(({ name, updatedAt }) => ({ name, updatedAt })),
        ...modules.reduce((rel, md) => {
            rel[md.name] = md.persist
                ? persistReducer(
                      processPersistency(md.persist, md.name),
                      md.reducer,
                  )
                : md.reducer;
            return rel;
        }, {} as Record<string, any>),
    });

    return persistReducer(
        {
            key: "root",
            storage,
            whitelist: [internalId],
            timeout: 1,
            version: 0,
            migrate: createMigrate(migrations),
        },
        newReducer,
    );
}

export async function fetchModules(
    fetcher: ModuleFetcher,
    names: InternalPersistency,
): Promise<ModuleData[]> {
    const promises = names.map(async ({ name, updatedAt }) => {
        const { id, hash } = splitModuleName(name);
        let response = await fetcher(id)
            .then((md) =>
                hash && md?.createModule
                    ? md.createModule({ hash })
                    : md?.default,
            )
            .catch(() => null);

        if (response) {
            response = Object.assign({}, response, {
                updatedAt: updatedAt || response.updatedAt || Date.now(),
            } satisfies Pick<ModuleData, "updatedAt">);
        }
        return response;
    });
    const responses = await Promise.all(promises);
    return responses.filter((resp): resp is ModuleData => !!resp);
}

export function moduleHydrationMiddleware(
    fetcher: ModuleFetcher,
): DynamicMiddleware<any> {
    return ({ addModules }) =>
        (next) =>
        async (action) => {
            if (
                action.type === REHYDRATE &&
                "key" in action &&
                action.key === "root" &&
                "payload" in action &&
                action.payload &&
                typeof action.payload === "object" &&
                internalId in action.payload
            ) {
                const persisted = action.payload[
                    internalId
                ] as InternalPersistency;
                addModules(await fetchModules(fetcher, persisted));
            }
            return next(action);
        };
}
