import { mapStepSpecs } from "./helpers";
import { moduleId, FlowSteps, FlowTypes } from "./interface";
import type { PayloadInfo, SignUpData, State } from "./interface";

import { createReducers } from "@redux/utils";
import { DEFAULT_STATUS } from "@redux/utils/helpers";

import { AccountPayloadPaymentGateway } from "@services/accounts/payloads/interface";
import getPaymentMethods from "@services/miscellaneous/getPaymentMethods";
import {
    getConsultant,
    ConsultantGatewayPgtoType,
} from "@services/supabase/consultants";

const fallbackId = process.env.REACT_APP_CONSULTANT_FALLBACK;

const reducers = createReducers({} as State)((creator) => ({
    fetchPaymentMethods: creator.asyncThunk(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async (_: undefined) => await getPaymentMethods(),
        {
            pending: (state) => {
                state.paymentMethods.status = DEFAULT_STATUS.LOADING;
            },
            fulfilled: (state, action) => {
                state.paymentMethods.data = action.payload;
                state.paymentMethods.status = DEFAULT_STATUS.IDLE;
            },
        },
    ),
    initialize: creator.asyncThunk(
        async (
            params: { consultantId: string | undefined; finished?: boolean },
            { getState },
        ) => {
            const root = getState() as Record<string, any>;
            const state = root[moduleId] as State;

            let payloadInfo = state.payloadInfo;
            let consultant = null;
            let message = null;

            if (params.finished && payloadInfo._id && !payloadInfo.finished) {
                payloadInfo = {
                    ...payloadInfo,
                    finished: true,
                    updatedAt: new Date().toISOString(),
                };
            }

            const {
                _id,
                finished,
                consultantId: _consultantId,
                payment,
                updatedAt,
            } = payloadInfo;

            const now = Date.now();
            const updatedDate = new Date(updatedAt);
            const expirationDate =
                payment?.qrCode?.expirationDate &&
                new Date(payment.qrCode.expirationDate);

            if (
                (expirationDate && expirationDate.getTime() < now) ||
                (_id &&
                    finished &&
                    now >= updatedDate.getTime() + 14_400_000) ||
                (_id &&
                    !finished &&
                    now >= updatedDate.getTime() + 3_600_000) ||
                (!finished && _consultantId !== params.consultantId)
            ) {
                payloadInfo = {
                    finished: false,
                    updatedAt: new Date().toISOString(),
                };
            }

            if (payloadInfo.finished) {
                return {
                    consultant,
                    payloadInfo,
                    message,
                    type: null,
                    step:
                        payloadInfo.gateway ===
                        AccountPayloadPaymentGateway.HOTMART
                            ? FlowSteps.HOTMART_CONFIRMATION
                            : FlowSteps.ASSAS_CONFIRMATION,
                };
            }

            if (params.consultantId) {
                consultant = await getConsultant(params.consultantId);
                if (consultant) {
                    const {
                        gateway_pgto,
                        id_hotmart_cnpj,
                        id_hotmart_cpf,
                        subscription_value,
                    } = consultant;
                    if (
                        (gateway_pgto === ConsultantGatewayPgtoType.ASAAS &&
                            !subscription_value) ||
                        (gateway_pgto === ConsultantGatewayPgtoType.HOTMART &&
                            !id_hotmart_cnpj &&
                            !id_hotmart_cpf)
                    ) {
                        consultant = null;
                        message = "Dados de consultor inválidos";
                    }
                }
            }

            if (!consultant && fallbackId) {
                consultant = await getConsultant(fallbackId);
            }

            if (!consultant) {
                message = "Dados do consultor não encontrados";
                return {
                    consultant,
                    payloadInfo,
                    message,
                    type: null,
                    step: null,
                };
            }

            switch (consultant.gateway_pgto) {
                case ConsultantGatewayPgtoType.ASAAS:
                    return {
                        consultant,
                        payloadInfo,
                        message,
                        type: FlowTypes.ASAAS,
                        step: FlowSteps.ASAAS_ACCOUNT_INFO,
                    };
                case ConsultantGatewayPgtoType.HOTMART:
                    return {
                        consultant,
                        payloadInfo,
                        message,
                        type: FlowTypes.HOTMART,
                        step: payloadInfo._id
                            ? FlowSteps.HOTMART_PAYMENT
                            : FlowSteps.HOTMART_DOCUMENT_VALIDATION,
                    };
                default:
                    return {
                        consultant,
                        payloadInfo,
                        message,
                        type: null,
                        step: null,
                    };
            }
        },
        {
            options: {
                condition: (_, { getState }) => {
                    const root = getState() as Record<string, any>;
                    const state = root[moduleId] as State | undefined;
                    return !!state?.hydrated;
                },
            },
            pending: (state) => {
                state.status = DEFAULT_STATUS.LOADING;
            },
            fulfilled: (state, action) => {
                const { consultant, payloadInfo, message, step, type } =
                    action.payload;

                state.consultant = consultant;
                state.flow = { step, type };
                state.payloadInfo = payloadInfo;
                state.message = message;
                state.status = message
                    ? DEFAULT_STATUS.FAILURE
                    : DEFAULT_STATUS.IDLE;
            },
        },
    ),
    updateData: creator.reducer<Partial<SignUpData>>((state, action) => {
        state.data = Object.assign({}, state.data, action.payload);
    }),
    updatePayloadInfo: creator.reducer<Omit<PayloadInfo, "updatedAt">>(
        (state, action) => {
            state.payloadInfo = {
                ...state.payloadInfo,
                ...action.payload,
                updatedAt: new Date().toISOString(),
            };
        },
    ),
    goToNextStep: creator.asyncThunk(
        async (
            onChangeStep:
                | ((
                      data: SignUpData,
                  ) =>
                      | void
                      | boolean
                      | string
                      | Promise<void | boolean | string>)
                | undefined,
            { getState, rejectWithValue },
        ) => {
            const root = getState() as Record<string, any>;
            const state = root[moduleId] as State;

            const flow = state.flow;
            const specs = mapStepSpecs[flow.step as FlowSteps];
            if (specs.next === null) return;

            const valid = specs.validation(state.data);
            if (!valid) return rejectWithValue("Dados Inválidos");

            if (onChangeStep) {
                const result = await onChangeStep(state.data);
                if (typeof result === "string") {
                    return rejectWithValue(result);
                }
                if (typeof result === "boolean" && !result) {
                    throw new Error("Failed");
                }
            }

            return specs.next;
        },
        {
            options: {
                condition: (_, { getState }) => {
                    const root = getState() as Record<string, any>;
                    const state = root[moduleId] as State | undefined;
                    return !!state && state.flow.step !== null;
                },
            },
            pending: (state) => {
                state.status = DEFAULT_STATUS.PROCESSING;
            },
            rejected: (state, action) => {
                if (typeof action.payload === "string") {
                    state.message = action.payload;
                    state.status = DEFAULT_STATUS.FAILURE;
                } else {
                    state.status = DEFAULT_STATUS.IDLE;
                }
            },
            fulfilled: (state, action) => {
                state.flow.step = action.payload ?? state.flow.step;
                state.status = DEFAULT_STATUS.IDLE;
            },
        },
    ),
    goToPrevStep: creator.asyncThunk(
        async (_: undefined, { getState }) => {
            const root = getState() as Record<string, any>;
            const state = root[moduleId] as State;

            const flow = state.flow;
            const specs = mapStepSpecs[flow.step as FlowSteps];
            if (specs.prev === null) return;

            return specs.prev;
        },
        {
            options: {
                condition: (_, { getState }) => {
                    const root = getState() as Record<string, any>;
                    const state = root[moduleId] as State | undefined;
                    return !!state && state.flow.step !== null;
                },
            },
            fulfilled: (state, action) => {
                state.flow.step = action.payload ?? state.flow.step;
            },
        },
    ),
}));

export default reducers;
