export interface ModuleSpecs {
    fixed?: boolean;
    index: 0 | 1;
    oldParams?: (params?: {
        paramsI?: any;
        paramsII?: any;
        paramsIII?: any;
        paramsIV?: any;
        paramsV?: any;
    }) => Record<string, any>;
    path: string;
    route?: string;
    tag: string;
    title: string | ((params: Record<string, any>) => string);
}

export interface ModuleItem {
    fixed?: boolean;
    key: string;
    oldKey?: string;
    params: Record<string, any>;
    relativeTo?: string;
    route?: string;
    search?: string;
    tag: string;
    title: string | ((params: Record<string, any>) => string);
}

export const currentVersion = 5;

export interface ModuleInjectedProps<
    Params extends Record<string, any> = Record<string, any>,
> {
    _moduleManager: {
        key: string;
        oldKey?: string;
        params: Params;
        tag: string;
    };
}
